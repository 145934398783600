<template>
  <swiper
    class="banner-swiper"
    :options="swiperOptions"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    :navigation="true"
  >
    <swiper-slide class="swiper-slide"><img class="swiper-img" title="智能燃气报警器" src="@/assets/images/banner1.png" /></swiper-slide>
    <swiper-slide class="swiper-slide"><img class="swiper-img" title="LPG优气物联网管理系统" src="@/assets/images/banner2.png" /></swiper-slide>
    <div ref="swiper-pagination" class="swiper-pagination" slot="pagination"></div>
    <div class="swiper-button" slot="button-next">
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import 'swiper/css/swiper.css'
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        autoplay:{delay: 3600},
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChangeTransitionStart: () => {
            setTimeout(() => {
              this.addClass()
            }, 100)
          }
        }
      },
    };
  },
  mounted(){
    // this.addClass()
    // const elem = this.$refs["swiper-pagination"].children[0]
    // this.addClass(elem)
    // elem.addClass('swiper-pagination-bullet-active-succ')
    // const beforeStyle = window.getComputedStyle(elem, ":before");
    // beforeStyle.width = "50%"
    // console.log(beforeStyle,"beforeStyle")
    // this.$refs["swiper-pagination"].children[0]

  },
  methods: {
    onSwiper(){},
    onSlideChange(){},
    addClass(){
      ([...new Set(this.$refs["swiper-pagination"].children)]).map(item => {
        item.classList.remove('swiper-pagination-bullet-active-succ');
        ([...new Set(item.classList)]).indexOf('swiper-pagination-bullet-active') != -1 && setTimeout(() => {
          item.classList.add('swiper-pagination-bullet-active-succ')
        }, 100);
      });
      
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-swiper {
  width: 100%;
  overflow: hidden;
  height: 640px;
  position: relative;
}
.swiper-slide{
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.swiper-img{
	height: 100%;
  position: absolute;
  left: calc(50% - 960px);
}
::v-deep .swiper-pagination-bullet{
  border-radius: 0;
  width: 100px;
  height: 4px;
}
::v-deep .swiper-pagination-bullet-active{
  background: #000;
  opacity: 0.2;
  position: relative;
  &::before{
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: white;
  }
}
::v-deep .swiper-pagination-bullet-active-succ{
  background: #000;
  opacity: 0.4;
  position: relative;
  &::before{
    content: "";
    transition: all 3s;
    width: 100px;
  }
}
::v-deep .swiper-button{
  opacity: 0;
  .swiper-button-next, .swiper-button-prev{
    &::after{
      color: #858585;
    }
  }
}
::v-deep .swiper-button-prev{
  left: 40px;
}
::v-deep .swiper-button-next{
  right: 40px;
}
.swiper-container:hover{
  ::v-deep .swiper-button{
    opacity: 1;
  }
}

@media screen and (max-width: 1600px){
  .banner-swiper {
    width: 100vw;
    height: 36vw;
  }
  .swiper-img{
    left: calc(50% - 54vw);
  }
}
// @media screen and (max-width: 1300px){
//   .banner-swiper {
//     width: 100vw;
//     height: 49vw;
//   }
//   .swiper-img{
//     left: calc(50% - 76vw);
//   }
// }
</style>